<template>
  <div>
    <page-title
      show-previous-button
      url-previous="/settings/cashierSettings/priceCheckTerminal"
      :heading="$t('settings.lang_priceCheckTerminal')"
      :subheading="$t('settings.lang_priceCheckTerminal')"
      :icon="icon"
    ></page-title>

    <div class="app-main__inner">
      <Templates></Templates>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import Templates from "../../../components/settings/cashier/priceCheckTerminal/Templates";

export default {
  name: "priceCheckTerminal",

  components: {
    PageTitle,
    Templates,
  },

  data: () => ({
    icon: "pe-7s-monitor icon-gradient bg-tempting-azure",
  }),
};
</script>

<template>
  <v-container fluid>
    <v-card outlined>
      <v-card-title class="ma-0 pa-0">
        <v-btn
          style="margin: 20px"
          color="success"
          :disabled="loading"
          :loading="loading"
          @click="showCreateDialog = true"
        >
          <v-icon>add</v-icon> {{ $t("erp.lang_newTemplate") }}
        </v-btn>
      </v-card-title>

      <v-card-text class="ma-0 pa-0">
        <Datatable
          ref="priceCheckTemplates"
          :api-endpoint="
            ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATES.DATATABLE
          "
          :datatable-headers="datatableHeaders"
          @editEntry="editEntry"
          @deleteEntry="deleteEntry"
          @deleteEntries="deleteEntries"
          show-delete-buttons
          show-edit-buttons
        />

        <v-dialog persistent max-width="400" v-model="showCreateDialog">
          <v-card>
            <v-card-title>
              {{ $t("settings.lang_createTemplate") }}
            </v-card-title>

            <v-card-text class="text-center">
              <v-text-field
                v-model="templateName"
                dense
                outlined
                :label="$t('erp.lang_TemplateName')"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="error"
                text
                :disabled="loading"
                @click="showCreateDialog = false"
              >
                {{ $t("generic.lang_cancel") }}</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :disabled="loading"
                :loading="loading"
                @click="createTemplate"
              >
                {{ $t("generic.lang_add") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import { Events } from "@/plugins/events";
import { mapState } from "vuex";
import Datatable from "@/components/datatable/Datatable";

export default {
  name: "Templates",
  components: {
    Datatable,
  },
  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      showCreateDialog: null,
      templateName: "",
    };
  },

  computed: {
    ...mapState(["api"]),
    datatableHeaders() {
      return [
        {
          text: "ID",
          align: "left",
          value: "id",
          width: 80,
          hide: true,
        },
        { text: this.$t("generic.lang_name"), value: "name" },
      ];
    },
  },
  methods: {
    createTemplate() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATES.CREATE, {
          templateName: this.templateName,
        })
        .then((res) => {
          if (res.data.success) {
            this.$router.push({
              name: "settings.cashierSettings.priceCheckTerminalTemplate",
              params: {
                id: res.data.templateID,
              },
            });
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    editEntry(entry) {
      this.$router.push({
        name: "settings.cashierSettings.priceCheckTerminalTemplate",
        params: { id: entry.id },
      });
    },
    deleteEntry(entry) {
      let id = [];
      id.push(entry.id);
      this.deleteData(id);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    deleteData(ids) {
      let self = this;
      this.$swal({
        title: this.$t("settings.lang_deletePriceCheckerTemplateHead"),
        text: this.$t("settings.lang_deletePriceCheckerTemplateBody"),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: this.$t("generic.lang_cancel"),
        confirmButtonText: this.$t("generic.lang_delete"),
        preConfirm: () => {
          this.axios
            .post(ENDPOINTS.SETTINGS.PRICECHECK_TERMINAL.TEMPLATES.DELETE, {
              id: ids,
            })
            .then((res) => {
              if (res.data.status == "SUCCESS") {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_ActionSuccessful"),
                  color: "success",
                });
                self.$refs.priceCheckTemplates.getDataFromApi();
                self.$refs.priceCheckTemplates.resetSelectedRows();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
  },
};
</script>
